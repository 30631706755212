#homepageBannerVideo {
  height: 100vh;
  width: 100%;
  object-position: left;
  object-fit: cover;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  z-index: -2;
}

.primary_btn_style {
  border: none;
  padding: 16px 32px;
  font-size: 0.85rem;
  letter-spacing: 4px;
  background-color: #ae7b5b;
  width: max-content;
  color: white;
}

.primary_btn_style:hover {
  text-decoration: none;
  color: white;
}

.service_btn {
  border: none;
  padding: 16px 32px;
  font-size: 0.85rem;
  letter-spacing: 4px;
}

.service_btn_mask {
  background-color: rgba(0, 0, 0, 0.1);
}

.service_btn_mask:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

/* @media (min-width: 766px) { */
@media (max-width: 991px) {
  .f1img {
    grid-area: f1img;
  }
  .f2img {
    grid-area: f2img;
  }
  .f3img {
    grid-area: f3img;
  }
  .f4img {
    grid-area: f4img;
  }
  .f5img {
    grid-area: f5img;
  }
  .f6img {
    grid-area: f6img;
  }
  .grid-homepage-gallery {
    display: grid;
    grid-row: repeat(5, 1fr);
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "f1img f2img"
      "f3img f2img"
      "f3img f5img"
      "f4img f5img"
      "f4img f6img";
    row-gap: 24px;
    column-gap: 24px;
  }
}

@media (min-width: 992px) {
  .f1img {
    grid-area: f1img;
  }
  .f2img {
    grid-area: f2img;
  }
  .f3img {
    grid-area: f3img;
  }
  .f4img {
    grid-area: f4img;
  }
  .f5img {
    grid-area: f5img;
  }
  .f6img {
    grid-area: f6img;
  }
  .grid-homepage-gallery {
    display: grid;
    grid-row: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "f1img f2img f3img"
      "f4img f2img f6img"
      "f4img f5img f6img";
    row-gap: 32px;
    column-gap: 32px;
  }
}

.custom-scissor-bullets {
  list-style-image: url("../assets/vectors/SVG/scissors.svg");
  padding: 10px 0;
}

li.custom-scissor-bullets::marker {
  color: red;
  transform: rotateY(180deg);
}

/* @media (max-width: 766px) {
  .grid-homepage-gallery > div {
    margin: 32px 0;
  }
} */

#bannerVideo {
  height: 100vh;
  width: 100%;
  object-position: center;
  object-fit: cover;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  z-index: -2;
}

.primary_btn_style {
  border: none;
  padding: 16px 32px;
  font-size: 1.2rem;
  letter-spacing: 4px;
  background-color: #ae7b5b;
  font-weight: bold;
  width: 100%;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
}

.primary_btn_style:hover {
  text-decoration: none;
  color: white;
}

@media (max-width: 991px) {
  .grid-gallery-1 > div {
    margin: 10px 0;
  }
}

@media (min-width: 992px) {
  .side-info {
    grid-area: side-info;
  }
  .img1 {
    grid-area: img1;
  }
  .img2 {
    grid-area: img2;
  }
  .img3-long {
    grid-area: img3-long;
  }
  .img4 {
    grid-area: img4;
  }
  .img5 {
    grid-area: img5;
  }
  .grid-gallery-1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas:
      "side-info img1 img2"
      "side-info img3-long img4"
      "side-info img3-long img5";
    row-gap: 10px;
    column-gap: 10px;
  }

  .grid-gallery-masonry {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: max-content;
  }
}

.faq-item {
  margin: 10px 0;
}

.faq-item:first-of-type,
.faq-item:last-of-type {
  border-radius: 0;
}

.accordion-button {
  font-weight: 500;
  color: black;
}
.accordion-button:focus {
  color: #ef251f;
}
.accordion-button:not(:focus) {
  color: black;
}

.faq-answer {
  font-size: calc(0.5rem + 0.5vw);
  text-align: justify;
}

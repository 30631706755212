form {
  /* transition: all 0.75s linear; */
  background-color: transparent;
  color: black;
  box-shadow: 0 0 5px 1px gray;
}

.form-control {
  border: none;
  border-bottom: 2px solid white;
  padding: 10px 12px;
  background-color: transparent;
  color: black;
}

.form-control::placeholder {
  background-color: transparent;
  color: rgb(41, 41, 41);
}

.form-control:focus {
  background-color: transparent;
  border: none;
}

.sbt {
  width: 100%;
  font-weight: 700;
  letter-spacing: 3px;
  padding: 0.9rem 1.5rem;
}

.form-toggle-btn {
  border-radius: 48px;
  cursor: pointer;
  width: 49%;
  padding: 0.5vmax 0.5vmax;
  /* transition: all 0.5s linear; */
}
